import { ICsrfResponse } from "@portal/types/api/auth/ICsrfResponse";
import { ISignInOptions } from "./types";
import { ISignInResponse } from "@portal/types/api/auth/ISignInResponse";

const signIn = async (signInOptions: ISignInOptions) => {
  const csrfResponse = await fetch("/api/auth/csrf");
  const csrf = (await csrfResponse.json()) as ICsrfResponse;

  const signInResponse = await fetch(`/api/auth/signin`, {
    method: "POST",
    headers: [
      ["Content-Type", "application/json"],
      ["accepts", "application/json"],
    ],
    body: JSON.stringify({
      csrf: csrf.csrf,
      internal: signInOptions.authType === "internal",
      callbackUrl: signInOptions.callbackUrl,
    }),
  });
  const signIn = (await signInResponse.json()) as ISignInResponse;

  let authProviderUrl = signIn.url;
  if (signInOptions.logonwithcenterid) {
    authProviderUrl += `&logonwithcenterid=${signInOptions.logonwithcenterid}`;
  }

  window.location.href = authProviderUrl;
};

export default signIn;
