import PortalPageBox from "@portal/components/atoms/portalPageBox";
import Typography from "@portal/components/atoms/typography";
import { useSearchParams } from "next/navigation";
import { TextLine } from "@portal/components/atoms/textLine";
import { ButtonComponent } from "@portal/components/atoms/buttons/main/ButtonComponent";
import { TextResources } from "@portal/assets/text/TextResources";
import { FC } from "react";
import { ISignInPageProps } from "./types";
import { trackMessage } from "@portal/appInsights";
import signOutPortalOnly from "@portal/auth/client/api/signOut/portalOnly";

const SignInPage: FC<ISignInPageProps> = ({ signInPath }) => {
  const searchParams = useSearchParams();

  if (!searchParams?.get("error")) return null;

  trackMessage("At signin page");

  return (
    <PortalPageBox>
      <div className="space-y-3">
        <div>
          <Typography variant="H6">{TextResources.SIGNIN_ERROR_HEADER}</Typography>
          <TextLine />
        </div>
        <div>
          <Typography variant="Body">{TextResources.SIGNIN_ERROR_PAGE_TEXT}</Typography>
        </div>
        <div>
          <ButtonComponent
            text={TextResources.SIGNIN_ERROR_LOGIN_BUTTON}
            wide
            onClick={async () => {
              await signOutPortalOnly();
              window.location.href = `${process.env.NEXT_PUBLIC_APPLICATION_BASE_URL}${signInPath}`;
            }}
          />
        </div>
      </div>
    </PortalPageBox>
  );
};

export default SignInPage;
